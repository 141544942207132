import { useEffect, useRef } from "react";
import {
  generateQRCode,
  directorName,
} from "../helpers/utils.helpers";

const Certificate = (data) => {
  const canvasRef = useRef(null);
  const backgroundImgData = data.backgroundData;
  const addValueAttest = (value, count, add = 0, multiply = 38) => {
    return value + multiply * count + add;
  };

  function addText(
    data = {
      ctx: Object,
      text: "",
      x: 0,
      y: 0,
      font: "",
      scale: [0.8, 1],
      maxWidth:0
    }
  ) {
    data.ctx.font = data.font;
    data.ctx.fillStyle = "black";
    data.ctx.fillText(data.text, data.x, data.y, data.maxWidth);
  }

  async function render() {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const personData = data.cerData;
    const qrcodeData = await generateQRCode(personData.qrcodeData);

    const topValue = 265;
    const startTitle = 150;
    const startTitleAcknowledgment = 100;
    const startValue = startTitle + 300;
    const addNum = 16;
    const imageWidth = 100;
    const width = 794;
    const height = 1115;

    canvas.width = width;
    canvas.height = height;

    const background = new Image(width, height);
    background.onload = () => {
      ctx.drawImage(background, 0, 0);

      addText({
        ctx: ctx,
        text: "Registration Center",
        x: startTitle,
        y: addValueAttest(topValue, 1.6),
        font: "14px Arial",
        scale: [1.25, 1],
      });

      addText({
        ctx: ctx,
        text: personData.RegistrationCenter,
        x: startValue,
        y: addValueAttest(topValue, 1.6),
        font: "bold 14px Arial",
        maxWidth:300,
      });
      addText({
        ctx: ctx,
        text: "LGA:",
        x: startTitle,
        y: addValueAttest(topValue, 2.1, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.lga,
        x: startValue,
        y: addValueAttest(topValue, 2.1, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "State:",
        x: startTitle,
        y: addValueAttest(topValue, 3, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.state,
        x: startValue,
        y: addValueAttest(topValue, 3, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: personData.acknowledgement,
        x: startTitleAcknowledgment,
        y: addValueAttest(topValue, 4, addNum),
        font: "15px Arial",
      });
      addText({
        ctx: ctx,
        text: personData.acknowledgement2,
        x: startTitleAcknowledgment,
        y: addValueAttest(topValue, 4, addNum + 30),
        font: "15px Arial",
      });
      addText({
        ctx: ctx,
        text: personData.date,
        x: startTitleAcknowledgment + 95,
        y: addValueAttest(topValue, 4, addNum + 30),
        font: "bold 15px Arial",
        maxWidth: 100,
      });
      addText({
        ctx: ctx,
        text: personData.acknowledgement3,
        x: startTitleAcknowledgment + 200,
        y: addValueAttest(topValue, 4, addNum + 30),
        font: "15px Arial",
      });

      addText({
        ctx: ctx,
        text: "Certificate Number:",
        x: startTitle,
        y: addValueAttest(topValue, 6, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.certificateNumber,
        x: startValue,
        y: addValueAttest(topValue, 6, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Full Name:",
        x: startTitle,
        y: addValueAttest(topValue, 7, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.fullname,
        x: startValue,
        y: addValueAttest(topValue, 7, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Sex:",
        x: startTitle,
        y: addValueAttest(topValue, 8, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.sex,
        x: startValue,
        y: addValueAttest(topValue, 8, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Date of Birth:",
        x: startTitle,
        y: addValueAttest(topValue, 9, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.dateOfBirth,
        x: startValue,
        y: addValueAttest(topValue, 9, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Place of Birth:",
        x: startTitle,
        y: addValueAttest(topValue, 10, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.placeOfBirth,
        x: startValue,
        y: addValueAttest(topValue, 10, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Full Name of Father:",
        x: startTitle,
        y: addValueAttest(topValue, 11, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.fatherFullname ?? "N/A",
        x: startValue,
        y: addValueAttest(topValue, 11, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Full Name of Mother:",
        x: startTitle,
        y: addValueAttest(topValue, 12, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.motherFullname ?? "N/A",
        x: startValue,
        y: addValueAttest(topValue, 12, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Name of Registrar:",
        x: startTitle,
        y: addValueAttest(topValue, 13, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: directorName(),
        x: startValue,
        y: addValueAttest(topValue, 13, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });

      addText({
        ctx: ctx,
        text: "Date:",
        x: startTitle,
        y: addValueAttest(topValue, 14, addNum),
        font: "14px Arial",
      });

      addText({
        ctx: ctx,
        text: personData.date,
        x: startValue,
        y: addValueAttest(topValue, 14, addNum),
        font: "bold 14px Arial",
        maxWidth: 250,
      });
    };
    background.src = backgroundImgData?.background;

    const signature = new Image(imageWidth, imageWidth);
    signature.onload = () => {
      ctx.drawImage(signature, width / 2 - 100, height - 295);
    };
    signature.src =  backgroundImgData?.signature;

    const qrcode = new Image(imageWidth, imageWidth);
    qrcode.onload = () => {
      ctx.drawImage(qrcode, width - 230, height -260);
    };
    qrcode.src = qrcodeData;
  }

  useEffect(() => {
    render();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default Certificate;
