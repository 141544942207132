import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import Certificate from "../components/Certificate";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { getCertificateTemplates } from "../redux/apiCalls";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";

function BulkPrintBirthCertificate() {
  const componentRef = useRef();
  const { certificates } = useSelector((state) => state.birth);
  const [backgroundData, setBackgroundData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCertificateTemplates();
        setBackgroundData(data);
        setLoading(false);

      } catch (error) {
        console.error(error);
        toast.error(error.response?.message, {
          position: "top-left",
        });
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {backgroundData ? (
            <>
              <div
                style={{
                  position: "sticky",
                  top: "60px",
                  backgroundColor: "#f8f9fa",
                  height: "73px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div className="w-full mb-4">
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button
                          type="button"
                          className="p-button-success"
                          label="Print Certificates"
                        />
                      );
                    }}
                    content={() => componentRef.current}
                  />
                </div>
              </div>

              <div ref={componentRef}>
                {certificates?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className=" mx-auto w-9">
                        <Certificate
                          backgroundData={backgroundData}
                          cerData={{
                            RegistrationCenter: item["Registration center:"],
                            certificateNumber: item["Certificate number:"],
                            fullname: item["Full name:"],
                            sex: item["Sex:"],
                            dateOfBirth: item["Date of birth:"],
                            state: item["State:"],
                            lga: item["LGA:"],
                            town: item["LGA:"] + " / " + item["State:"],
                            placeOfBirth: item["Place of birth:"],
                            motherFullname: item["Full name of mother:"],
                            fatherFullname: item["Full name of father:"],
                            qrcodeData: item["qrcodeData"],
                            acknowledgement: item["acknoladgement"],
                            acknowledgement2: item["acknoladgement2"],
                            acknowledgement3: item["acknoladgement3"],
                            date: item["Date:"],
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            "nothingg"
          )}
        </>
      )}
    </>
  );
}

export default BulkPrintBirthCertificate;
